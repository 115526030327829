(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/widget-area/assets/javascripts/load-area.js') >= 0) return;  svs.modules.push('/components/core/widget-area/assets/javascripts/load-area.js');

'use strict';

const _excluded = ["fetchDataUrl"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function loadArea(area, className, finalCallback) {
  if (finalCallback === undefined) {
    finalCallback = className;
    className = undefined;
  }
  const widgets = svs.widgets.data[area];
  if (widgets) {
    const widgetsToLoad = widgets
    .filter(widget => widget.data && widget.data.fetchDataUrl)
    .map(widget => {
      const d = $.Deferred();
      const widgetName = widget.widget;
      const widgetComponent = svs.widget[widgetName];
      if (widgetComponent && widgetComponent.fetchData) {
        const _widget$data = widget.data,
          {
            fetchDataUrl: url
          } = _widget$data,
          data = _objectWithoutProperties(_widget$data, _excluded);
        widgetComponent.fetchData({
          area,
          url
        }, data, (err, completeData) => {
          if (completeData) {
            widget.data = completeData;
            widget.partial = "widget-".concat(widgetName, "-").concat(Object.keys(widgetComponent.templates)[0]);
          }
          d.resolve();
        });
      } else {
        d.resolve();
      }
      return d;
    });

    $.when.apply(this, widgetsToLoad).then(() => {
      const templateString = Handlebars.helpers.widgetArea({
        hash: {
          name: area,
          class: className
        }
      });
      finalCallback(templateString);
    }, () => {
      finalCallback(); 
    });
  } else {
    finalCallback();
  }
}
svs.core.widget_area.loadArea = loadArea;

 })(window);